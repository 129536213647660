require('../css/app.scss');
require('bootstrap');

const $ = require('jquery');
global.$ = global.jQuery = $;

import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(require('../../public/js/fos_js_routes.json'));

window.Routing = Routing;

let loaderHtml = $(document).find('.tplPart#spinner').html();

$('#checkoutModal').on('shown.bs.modal', function () {

    let $el = $(this),
        $content = $el.find('.modal-content');

    $content.html(loaderHtml);

    $.ajax({
        method: 'POST',
        type: 'json',
        url: Routing.generate('app_modal_checkout', {}),
        data: {}
    }).done(function(res) {

        if(res.success == true) {
            $content.html(res.html);
        }
        else {
            $el.modal('hide');
        }

    });

});

$('.menu-toggle').on('click', function() {
    $('#mobileMenu').toggleClass('open');
});